<template>
  <div id="user-index">
    <k-crud-layout :search.sync="searchQuery">
      <template #header>
        {{ $tc('user.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('user.title', 1) }) }}
        </v-btn>
        <UserForm
          v-model="createDialog"
          :request="createRequest"
          :values="createFormValues"
          :title="$tc('user.title', 1)"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="user.fields"
          resource="user"
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'settings.users.show', params: { userId: $event.id } })"
        />
      </template>
    </k-crud-layout>
    <UserForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('user.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import { index, create, show, update } from '@/modules/users/api';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import UserForm from '@/modules/users/components/UserForm.vue';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import eventBus from '@/application/eventBus.ts';
import User from '@/application/models/User.js';
import { mapActions } from 'vuex';

export default {
  name: 'TableVue',
  components: {
    KCrudTable,
    UserForm,
    KCrudLayout,
  },
  data() {
    return {
      createDialog: false,
      updateDialog: false,
      createFormValues: new User(),
      updateFormValues: new User(),
      searchQuery: '',
      crudHeaders: [
        { value: 'firstName' },
        { value: 'lastName' },
        { value: 'email' },
      ],
    };
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.users' },
        text: this.$tc('user.title', 2),
      },
    ]);
  },
  methods: {
    ...mapActions({
      fetchProfile: 'authorisation/fetchProfile',
    }),
    handleOpenCreateDialog() {
      this.createFormValues = new User();
      this.createDialog = true;
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    async updateRequest() {
      await update(this.updateFormValues);
      this.fetchProfile();
    },
    async openUpdate(item) {
      this.updateFormValues = new User();
      const userShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(userShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
